<template>
	<div>
        <div class="d-flex justify-content-between align-items-center position-relative">
            <select :value="dia" :class="[classInput, {'custom-select custom-select-sm':true}]" style="max-width: 80px;" @change="date($event, 'd')" :disabled="disabledDF" :required="requiredDF">
                <option value="" selected disabled>Día</option>
                <option v-for="(n, index) in 31" :value="n" :key="index">{{n}}</option>
            </select>
            <select :value="mes" :class="[classInput, {'custom-select custom-select-sm':true}]" style="max-width: 135px;" @change="date($event, 'm')" :disabled="disabledDF" :required="requiredDF">
                <option value="" selected disabled>Mes</option>
                <option v-for="(n, index) in 12" :value="n" :key="index">{{nombresMeses[n-1]}}</option>
            </select>
            <select :value="anio" :class="[classInput, {'custom-select custom-select-sm':true}]" style="max-width: 90px;" @change="date($event, 'y')" :disabled="disabledDF" :required="requiredDF">
                <option value="" selected disabled>Año</option>
                <option v-for="(n, index) in anios" :value="n" :key="index">{{n}}</option>
            </select>
            <div v-if="validateDate" class="invalid-tooltip d-block">
                La fecha es incorrecta.
            </div>
        </div>
    </div>
</template>
<script>
    export default {
        props: ['modelValue', 'disabled', 'required', 'classInput'],
        emits: ['update:modelValue'],

        data(){
            return {
                dia: '',
                mes: '',
                anio: '',
                validateDate: false
            }
        },

        mounted(){
            this.setValue()
        },

        methods:{
            date(event, type){
                if (type=='d') {
                    this.dia = event.target.value
                }
                if (type=='m') {
                    this.mes = event.target.value
                }
                if (type=='y') {
                    this.anio = event.target.value
                }
                if (this.dia && this.mes && this.anio) {
                    if (this.isValidDate(this.dia, this.mes, this.anio)) {
                        let fecha = new Date()
                        fecha.setYear(this.anio)
                        fecha.setMonth(parseInt(this.mes)-1)
                        fecha.setDate(this.dia)

                        let val = null

                        let year = fecha.getFullYear()
                        let month = fecha.getMonth()+1
                        if(month.toString().length==1){
                            month = `0${month}`
                        }

                        let day = fecha.getDate()
                        if(day.toString().length==1){
                            day = `0${day}`
                        }

                        val = `${year}-${month}-${day}`
                        this.$emit('update:modelValue', val)
                        return this.validateDate = false
                    }else{
                        return this.validateDate = true
                    }
                }else{
                    return this.validateDate = true
                }
            },

            daysInMonth(m, y) {
                m = parseInt(m)
                y = parseInt(y)
                switch (m) {
                    case 1 :
                        return (y % 4 == 0 && y % 100) || y % 400 == 0 ? 29 : 28;
                    case 8 : case 3 : case 5 : case 10 :
                        return 30;
                    default :
                        return 31
                }
            },

            isValidDate(d, m, y) {
                m = parseInt(m, 10) - 1;
                d = parseInt(d);
                return m >= 0 && m < 12 && d > 0 && d <= this.daysInMonth(m, y);
            },

            setValue(){
                let dd = this.modelValue || ''
                if (dd != '') {
                    let fecha = new Date(dd.slice(0, 10).replace(/-/g, '/'))
                    let dia = fecha.getDate()
                    let mes = fecha.getMonth()+1
                    let anio = fecha.getFullYear()

                    if(this.isValidDate(dia, mes, anio)){
                        this.dia = dia
                        this.mes = mes
                        this.anio = anio

                        if(mes.toString().length==1){
                            mes = `0${mes}`
                        }

                        if(dia.toString().length==1){
                            dia = `0${dia}`
                        }

                        this.$emit('update:modelValue', `${anio}-${mes}-${dia}`)
                    }
                }
            }
        },

        computed: {
            anios(){
                let max = new Date().getFullYear()
                let min = max - 99
                let years = []
                for (let i = max; i >= min; i--) {
                    years.push(i)
                }
                return years
                //return Array.from(Array(116).keys()).reverse()
            },

            nombresMeses(){
                return ['enero', 'febrero', 'marzo', 'abril', 'mayo', 'junio', 'julio', 'agosto', 'septiembre', 'octubre', 'noviembre', 'diciembre']  
            },
            disabledDF(){
                return this.disabled
            },
            requiredDF(){
                return this.required
            }
        },
        watch:{
            modelValue(val){
                this.setValue()
                return val
            }
        }
    }
</script>